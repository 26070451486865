.spinner-overlay {
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.spinner-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}


.table-responsive {
  overflow-x: auto;
}

.customize-pagination-ag-agrid {
  position: relative;
}

.pagination-row {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-top: none;
}

.pagination-row-summary-panel,
.pagination-page-summary-panel,
.pagination-page-size-panel {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.pagination-row-summary-value,
.pagination-page-of-value {
  margin: 0 5px;
  font-weight: bold;
}

.pagination-page-summary-icon {
  cursor: pointer;
  margin: 0 5px;
}

.pagination-page-size-panel {
  display: flex;
  align-items: center;
}

.pagination-page-size-panel label {
  margin-right: 5px;
  margin-bottom: 0px;
}

.pagination-page-size-panel select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.price-range-loopnet {
  display: flex;
}

.price-range-loopnet .min {
  margin-right: 5px;
}

.price-range-loopnet .max {
  margin-left: 5px;
}

.view-loopnet-data .property-header {
  text-align: center;
}

.view-loopnet-data .property-header h1 {
  margin: 0;
  font-size: 30px;
  color: #007BFF;
  font-weight: 600;
}

.view-loopnet-data .property-header p {
  margin: 5px 0;
  color: #000;
  font-size: 18px;
}

.view-loopnet-data .property-images {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;

}

.view-loopnet-data .carousel-item {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.view-loopnet-data .carousel-image-container {
  width: 100%;
  height: 300px;
  /* Set a fixed height for the container */
  overflow: hidden;
  /* Hide any overflowing part of the image */
}

.view-loopnet-data .carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.view-loopnet-data .highlights,
.view-loopnet-data .executive-summary,
.view-loopnet-data .property-facts,
.view-loopnet-data .space-availability,
.view-loopnet-data .map-container,
.view-loopnet-data .property-taxes,
.view-loopnet-data .brokers,
.view-loopnet-data .footer {
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
}

.view-loopnet-data .view-loopnet-data .highlights ul,
.view-loopnet-data .executive-summary ul {
  list-style-type: none;
  padding: 0;
}

.view-loopnet-data .highlights ul li,
.view-loopnet-data .executive-summary ul li {
  margin-bottom: 10px;
}

.view-loopnet-data .property-facts p,
.view-loopnet-data .property-taxes p,
.view-loopnet-data .brokers p {
  margin: 5px 0;
}

.view-loopnet-data .property-facts p strong,
.view-loopnet-data .property-taxes p strong,
.view-loopnet-data .brokers p strong {
  color: #333;
}

.view-loopnet-data .space-availability table {
  width: 100%;
}

.view-loopnet-data .brokers {
  text-align: center;
}

.view-loopnet-data .brokers h2 {
  color: #d52330;
}

.view-loopnet-data .brokers p {
  font-size: 1.1rem;
  color: #555;
}

.view-loopnet-data .footer {
  padding: 10px 0;
  text-align: center;
  color: #555;
}

.view-loopnet-data .footer p {
  margin: 5px 0;
}


.view-loopnet-data .map-container iframe {
  max-width: 100%;
  border-radius: 8px;
}

.view-loopnet-data .fa-map-marker {
  font-size: 22px;
  color: red;
}

.loopnet_header_button {
  height: 38px;
}

.modal-90w {
  width: 90%;
  max-width: none;
}

.css-1nmdiq5-menu {
  z-index: 9 !important;
}

.view-loopnet-data .modal-body {
  max-height: 77vh;
  overflow-y: auto;
}

.close_icon_modal {
  cursor: pointer;
  font-size: 20px;
}

.login-box-msg,
.register-box-msg {
  font-size: 25px;
}

.register-box-msg-forgot {
  font-size: 15px;
}

.login-header-text {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #0074F0;
  font-size: 38px;
}

.view-loopnet-data .property-images .react-multi-carousel-list {
  position: unset !important;
}

.view-loopnet-data .property-images .react-multiple-carousel__arrow--right {
  right: calc(1.5% + 1px) !important;
}

.view-loopnet-data .property-images .react-multiple-carousel__arrow--left {
  left: calc(1.5% + 1px) !important;
}

.tooltip-pdf {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  z-index: 99999 !important;
}

.tooltip-pdf .tooltiptext {
  visibility: hidden;

  overflow: hidden;
  width: auto;
  max-width: 1000px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 9999 !important;
  right: -45px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.tooltip-pdf:hover .tooltiptext {
  visibility: visible;
  opacity: 1;

}

.tooltip-pdf ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.tooltip-pdf li {
  margin: 0;
  padding: 2px 0;
}

.tooltip-ppdf .tooltiptext::after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.fetch-data-btn {
  width: 100px;
}

.fetch-cursor-none {
  cursor: auto !important;
}

.form-label {
  word-wrap: break-word;
}

.error-page {
  margin: 0px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.error-page>.error-content {
  display: block;
  margin-left: 40px;
}

.user-status-btn {
  width: 80px;
}

.card-body-profile .form-group {
  padding: 10px 10px 10px 15px;
  margin: 0px;
  word-wrap: break-word;
}

.card-body-profile .dropdown-footer {
  cursor: pointer;
}

.card-body-profile .fa-user-circle {
  font-size: 25px;
}

.card-body-profile .nav-link {
  padding: 8px !important;
}

.select-width {
  width: 425px;
}

.input-width {
  width: 150px;
}

.tabs {
  display: flex;
  gap: 10px; /* Space between buttons */
}

.tab-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
}

.tab-button.active {
  background-color: #007bff;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.tab-button:hover:not(.active) {
  background-color: #e1e1e1;
}
.text-blue {
  color: blue;
}

